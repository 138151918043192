// Import the functions you need from the SDKs you need
import { getToken, onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { http } from "./http";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCadnEdhrZxzu2VkzTzVSla4YDNFj9W_O8",
  authDomain: "gs-absen.firebaseapp.com",
  projectId: "gs-absen",
  storageBucket: "gs-absen.appspot.com",
  messagingSenderId: "353624386534",
  appId: "1:353624386534:web:c6c5873176e43caf644427",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  console.log("onMessage test event fired", payload);
});

const key =
"BCpUMmKEOvRyWk6lorcTGoRr9aMp1ep5Gpi8Cf6Bbc6QuXW34U8vb6ejH5FOUtXLl2zJDY8sNCKdGr7f2S4nxa0";
getToken(messaging, {
  vapidKey: key,
})
  .then(async (currentToken) => {
    if (currentToken) {
      updateUserToken(currentToken);
      let lcFcm = localStorage.getItem("fcm_token");
      console.log(lcFcm);
      console.log("client token", currentToken);
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
  });

// const generateToken = () => {
//   let firebaseToken = "";
//   // if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("firebase-msg-sw.js")
//     .then((registration) => {
//       getToken(messaging, {
//         serviceWorkerRegistration: registration,
//         vapidKey: key,
//       })
//         .then(async (currentToken) => {
//           if (currentToken) {
//             updateUserToken(currentToken);
//             let lcFcm = localStorage.getItem("fcm_token");
//             console.log(lcFcm);
//             // if(lcFcm)
//             console.log("client token", currentToken);
//           } else {
//             console.log(
//               "No registration token available. Request permission to generate one."
//             );
//           }
//         })
//       onMessage(messaging, (payload) => {
//         console.log("onMessage event fired", payload);
//       });
//     });
//   // }

//   return firebaseToken;
// };

const updateUserToken = async (token) => {
  const userAgent = navigator.userAgent;
  let deviceType = "desktop";

  if (/Android/i.test(userAgent)) {
    deviceType = "android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    deviceType = "ios";
  }

  await http
    .post("profil/fcm-token-update", {
      device: deviceType,
      token: token,
    })
    .then((resp) => {
      console.log(resp);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { updateUserToken, messaging };

export default app;
