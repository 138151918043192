import axios from "axios";
import Cookies from "js-cookie";
// import { useToastStore } from '@/store/toast'

// const url = "http://localhost/gsl-absen/backend/api";
// const webUrl = "http://localhost/gsl-absen/backend"; //WEB APACHE
// const url = "https://stag.gsabsen.my.id/backend/api";
// const webUrl = "https://stag.gsabsen.my.id/backend"; //WEB APACHE
// const url = "http://192.168.3.211/x-absensi/backend/api"; //WEB APACHE
// const webUrl = "http://192.168.3.211/x-absensi/backend"; //WEB APACHE
const url = "https://gslabsen.my.id/backend/api"; //WEB APACHE
const webUrl = "https://gslabsen.my.id/backend"; //WEB APACHE

const token =
  Cookies.get("user") != undefined ? JSON.parse(Cookies.get("user")).token : "";

const http = axios.create({
  baseURL: url,
  headers: {
    Authorization: "Bearer " + token,
    Accept: "application/json",
  },
  withCredentials: true,
});

http.interceptors.request.use(
  (x) => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)

    return x;
  },
  (error) => {
    return Promise.reject(error.message);
  }
);

http.interceptors.response.use(
  (x) => {
    return x;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove("user");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { http, url, webUrl };
