import { http } from "@/config";

class JadwalService {
  constructor(http) {
    this.http = http;
  }

  async all({ bawahan }) {
    try {
      let q = bawahan === "" ? "" : `?bawahan=${bawahan}`;

      const { data } = await this.http.get(`/jadwal${q}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async data() {
    try {
      const { data } = await this.http.get(`/jadwal/all/data`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async store(form) {
    try {
      const { data } = await this.http.post(`/jadwal`, form);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async storeWithRange(jadwal) {
    try {
      const { data } = await this.http.post(`/jadwal-all-range`, jadwal);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async storeAll(jadwal) {
    try {
      const { data } = await this.http.post(`/jadwal-all`, jadwal);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async show(params) {
    try {
      const { data } = await this.http.get(`/jadwal-show?${params}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async showByNip({ nip, month, year }) {
    try {
      let query = "";
      if (year !== undefined) {
        query += `?year=${year}`;
      }
      if (month !== undefined) {
        query += `&month=${month}`;
      }
      const { data } = await this.http.get(`/jadwal/${nip}/nip${query}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async jadwalku() {
    try {
      const { data } = await this.http.get(`/jadwalku`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async jadwalkuHarian() {
    try {
      const { data } = await this.http.get(`/jadwalku-harian`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async updateJadwalku(kode_shift, id) {
    try {
      const { data } = await this.http.put(`update/jadwalku/${id}`, {
        kode_shift: kode_shift,
      });

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async updateLibur(form, id) {
    try {
      const { data } = await this.http.put(`/jadwal/${id}?column=libur`, form);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async updateShift(form, id) {
    try {
      const { data } = await this.http.put(`/jadwal/${id}?column=shift`, form);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }

  async delete(id) {
    try {
      const { data } = await this.http.delete(`/jadwal/${id}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async deleteAll(ids) {
    try {
      const { data } = await this.http.delete(`/jadwal/all`, { id: ids });

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async importExcel(params) {
    try {
      let year = params.get("year");
      let month = params.get("month");
      let file = params.get("file");
      const { data } = await this.http.post(
        `/jadwal/import?year=${year}&month=${month}`,
        { file: file },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async templateExcel() {
    try {
      const { data } = await this.http.get(
        `/jadwal/template/unit?jenis=excel`,
        {
          headers: {
            responseType: "blob",
          },
        }
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async showJadwalUnit(query) {
    try {
      const { data } = await this.http.get(`/jadwal-unit?${query}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async jadwalUnitStatus(query) {
    try {
      const { data } = await this.http.get(`/jadwal-unit-status?${query}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async approval(params) {
    try {
      const { data } = await this.http.post(`/jadwal/approval`, params);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async kosongkanJadwal(params) {
    try {
      const { data } = await this.http.post(
        `/kosongkan-jadwal?${params.query}`,
        { list_nip: params.list_nip }
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  /**
   * CODE BLUE
   */
  async getCodeBlue(query) {
    try {
      const { data } = await this.http.get(`/jadwal-codeblue/list?${query}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async showCodeBlueHarian(query) {
    try {
      const { data } = await this.http.get(
        `/jadwal-codeblue-harian/list?${query}`
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async showCodeBlueHarianOpen(query) {
    try {
      const { data } = await this.http.get(
        `/jadwal-codeblue-harian-open/list?${query}`
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async generateCodeBlue(params) {
    try {
      const { data } = await this.http.post(
        `/jadwal-codeblue/generate`,
        params
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async karyawanShift(query) {
    try {
      const { data } = await this.http.get(
        `/jadwal-codeblue/karyawan-shift?${query}`
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async updateCodeBlue(params) {
    try {
      const { data } = await this.http.put(`/jadwal-codeblue/update`, params);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  /**
   * CODE RED
   */
  async getCodeRed(query) {
    try {
      const { data } = await this.http.get(`/jadwal-codered/list?${query}`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async showCodeRedHarian(query) {
    try {
      const { data } = await this.http.get(
        `/jadwal-codered-harian/list?${query}`
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async showCodeRedHarianOpen(query) {
    try {
      const { data } = await this.http.get(
        `/jadwal-codered-harian-open/list?${query}`
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async zonaCodeRed() {
    try {
      const { data } = await this.http.get(`/jadwal-codered-zona`);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async storeZonaCodeRed(form) {
    try {
      const { data } = await this.http.post(`/jadwal-codered-zona`, form);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async generateCodeRed(params) {
    try {
      const { data } = await this.http.post(`/jadwal-codered/generate`, params);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async karyawanShiftCodeRed(query) {
    try {
      const { data } = await this.http.get(
        `/jadwal-codered/karyawan-shift?${query}`
      );

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
  async updateCodeRed(params) {
    try {
      const { data } = await this.http.put(`/jadwal-codered/update`, params);

      return [null, data];
    } catch (error) {
      return [error];
    }
  }
}

export const jadwalService = new JadwalService(http);
